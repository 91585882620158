<template>
  <div class="container">
    <div class="row">
      <PageDesignsGridItem
        :cols="cols"
        :key="index"
        v-for="(item, index) in getInternalPages"
        :item="item"
        type="cover"
        @triggerPreview="triggerPreview"
        @triggerSubmit="triggerSubmit"
        :currentValue="currentValue"
        :loading="loading"
      />
    </div>
  </div>
</template>
<script>
import PageDesignsGridItem from "./PageDesignsGridItem";
import { mapGetters } from "vuex";
import PreviewImage from "./PreviewImage.vue";

export default {
  components: {
    PageDesignsGridItem,
  },
  data() {
    return {
      loading: true,
    };
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns", "getInternalDesignData"]),
    getInternalPages() {
      return this.getPageDesigns.filter((d) =>
        d.groups.find((g) => g === "default")
      );
    },
    currentValue: {
      get() {
        return this.getInternalDesignData;
      },
      set(value) {
        this.$store.commit("SET_INTERNAL_DESIGN_DATA", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch("getInternalDesign", (data) => {
      this.currentValue = data.value;
      this.loading = false;
    });
  },
  methods: {
    triggerPreview(props) {
      if (!props.download_link) {
        this.triggerSubmit(props);
      } else {
        this.$modal.show(
          PreviewImage,
          {
            item: props,
            id: "",
            type: "internal",
          },
          {
            name: "modal-" + props.id,
          }
        );
      }
    },
    triggerSubmit(props) {
      this.currentValue = props.id;
      this.$store.dispatch("updateUserMeta", [
        "legacy_journal_default_page",
        props.id,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
};
</script>

<style scoped>
.draggable-grid {
  background: transparent !important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>